import React, { ReactElement } from 'react';
import { ReactComponent as LoadingIcon } from '../../assets/imgs/loading.svg';
import './Loading.scss';
import clsx from 'clsx';

interface LoadingProps {
  message?: string;
  className?: string;
}

const Loading = ({ message, className }: LoadingProps): ReactElement => {
  return (
    <div className={clsx('loading', className ? className : '')}>
      <LoadingIcon data-testid="loading-icon" />
      <h2>{message}</h2>
    </div>
  );
};

export default Loading;
