import { ActionType } from '../context/actions';
import { MediaId, ValidationError } from '../context/photoConstants';
import { FieldSection } from './form';

export interface IState {
  preInspectionId: string | null;
  preInspectionModel: IPreInspectionModel;
  photos: { [key in MediaId]: Photo | Photo[] };
  formData: FieldSection[] | null;
  formDataInit: FieldSection[] | null;
  extractedData: IExtractedData;
  alertData: IAlertData[] | null;
  location: IGeoLocation | null;
  ipLocation: IGeoLocation | null;
  finishStatus: FinishStatus | null;
  externalCarImagesIsBlurry: boolean | null;
  refreshGalleryOneScreen: boolean | null;
}

interface IExtractedData {
  plate: string | null;
  vin: string | null;
}

export interface IGeoLocation {
  lat: string;
  lng: string;
}

export interface IAlertData {
  code: string | null;
  dataCompare: string | null;
}

export interface IExteriorData {
  id?: number;
  zoneName?: string;
  detectZone?: string;
  s3Url: string;
  webHook?: string;
  result?: string;
}

export enum CountryCode {
  PRI = 'PRI',
  CRC = 'CRC',
  PAN = 'PAN',
}

export enum BrandsId {
  SURA = 15,
  CSM = 11,
  LAFISE = 6,
  OCEANICA = 4,
  OPTIMA = 16,
  ASSA = 2,
  QUALITAS = 1,
  INTER_DE_SEGUROS = 7,
  GENERAL_DE_SEGUROS = 8,
  NACIONAL_DE_SEGUROS = 18,
  PREMIER = 25,
  ACERTA = 27,
  OPTIMA_SEGUROS = 29,
}

export interface Photo {
  id: MediaId;
  notValidZone?: boolean;
  url?: string | undefined;
  base64?: string | undefined;
  attemps?: number | undefined;
  notValid?: boolean | undefined;
  isBlurry?: boolean | undefined;
  uniqueId?: string | undefined;
  loading?: boolean;
  error?: ValidationError;
}

export interface SelectOption {
  title: string;
  formSectionId: number;
  idOrder: number;
  isSelected?: boolean;
  hasError?: boolean;
}

export interface SelectedValues {
  label: string;
  value: string;
}

export interface IAppContext {
  state: IState;
  dispatch: DispatchFunction;
}

export type DispatchFunction = (event: IAction) => void;

export interface IAction {
  type: ActionType;
  payload?: any;
}

export interface IPreInspectionModel {
  preInspectionId: number;
  preCompanyId: number;
  preInsuranceType: string;
  preIdType: string;
  preIdInsured: string;
  preName: string;
  preLastName1: string;
  preLastName2: string;
  preBirthDate: string;
  prePhoneNumber: string;
  preEmail: string;
  preProvince: string;
  preCanton: string;
  preDistrict: string;
  preBrokerCode: string;
  preBrokerName: string;
  preCreationDate: string;
  preVehicleBrand: string;
  preVehicleModel: string;
  preVehicleYear: number;
  preVehicleType: string;
  preVehicleVin: string;
  preVehicleMotor: string;
  preVehicleColor: string;
  prePaxNumber: number;
  preTransmission: string;
  preCylinderCapacity: number;
  preInsuranceAmount: number;
  preInitialValidity: string;
  preFinalValidity: string;
  prePlanType: string;
  preCoveragePlan: string;
  preBenefits: string;
  preQuoteNumber: string;
  preVehiclePlate: string;
  preSignature: string;
  preTermsAcceptance: boolean;
  preRedirectURL: string;
  preIsInactive: boolean;
  preEmisionType: number;
  preIsBroker: boolean;
  preActiveQuestionBroker: boolean;
}

export interface VehicleSide {
  id: MediaId;
  name: string;
  sideValidation: string;
}

export enum FinishStatus {
  SATISFACTORIO = 'SATISFACTORIO',
  NO_SATISFACTORIO = 'NO SATISFACTORIO',
}

export interface IVehicleInsightsResult {
  isVehicle: boolean | false;
  side: string | 'NONE';
  quality: boolean | false;
  description: string | '';
}
